import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import s from './NotFound.css';
import errorImage from './404_notfound.svg';

class NotFound extends React.Component {
  render() {
    return (
      <div className={s.root}>
        <div className={s.imageContainer}>
          <img className={s.image} alt="Error message" src={errorImage} />
        </div>
        <div className={s.container}>
          <h1>
            <FormattedMessage
              id="not_found.context"
              defaultMessage="Sorry this page can`t be found"
            />
          </h1>
          <a href="/">
            <FormattedMessage id="not_found.link" defaultMessage="Home page" />
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(NotFound);
