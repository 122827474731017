import React from 'react';
import DESCRIPTION from '../../constants/seoDescriptions';
import TITLE from '../../constants/seoTitles';
import Layout from '../../components/Layout';
import NotFound from './NotFound';

function action({ translate }) {
  const title = TITLE.NOT_FOUND(translate);
  const description = DESCRIPTION.NOT_FOUND(translate);
  return {
    status: 404,
    chunks: ['not-found'],
    title,
    description,
    component: (
      <Layout>
        <NotFound title={title} description={description} />
      </Layout>
    ),
  };
}

export default action;
