const DESCRIPTION = {
  HOME: (props, t) => t('E-Shop'),
  NOT_FOUND: t => t('Sorry this page can`t be found'),
  SECURITY: t =>
    t(
      'Fondy pays great attention to the security of credit cards of our clients',
    ),
  CONTACTS: t => t('Fondy Contacts'),
  CUSTOMIZE: t => t('Customize you Checkout page'),
  THANK_YOU: t => t('Thank you for shopping us!'),

  STATIC_PAGE: {
    CART: t => t('Cart'),
  },
};

export default DESCRIPTION;
