const TITLE = {
  HOME: (props, t) => t('E-Shop'),
  NOT_FOUND: t => t('Page not found'),
  SECURITY: t => t('Security'),
  CONTACTS: t => t('Contacts'),
  THANK_YOU: t => t('Thank You'),
  CUSTOMIZE: t => t('Fondy Customize Checkout'),

  STATIC_PAGE: {
    CART: t => t('Cart'),
  },
};

export default TITLE;
